import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import Accordion from './Accordion';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import dayjs from 'dayjs';
import "dayjs/locale/es";
import utc from 'dayjs/plugin/utc';
import { useAuth } from "../pages/login/OAuth";
import UploadDocument from "./UploadDocument"
import CreatePdf from "./CreatePdf"
import { formatDate, formatPrice, DecimalTextField } from "./Utils"

dayjs.extend(utc);

const InvoiceRetentionTable = ({
    isInvoice,
    isClient,
    showBuilding,
    showClientOrProvider,
    title,
    totalAmount,
    paidAmount,
    remainingAmountSum,
    invoices,
    fetch,
    uploadDocument,
    handleOpenDeleteDialog
}) => {
    const { api } = useAuth();

    const [loading, setLoading] = useState(false);
    const [invoicesAmounts, setInvoicesAmounts] = useState({});
    const [retentionsAmounts, setRetentionsAmounts] = useState({});
    const [invoicesErrors, setInvoicesErrors] = useState({});
    const [retentionsErrors, setRetentionsErrors] = useState({});

    const [newDate, setNewDate] = useState({});

    useEffect(() => {
        const initializeRetentionDates = () => {
            const dates = {};
            invoices.forEach((invoice) => {
                dates[invoice.id] = invoice.retention_limit_date;
            });
            setNewDate(dates);
        };
        if (!isInvoice) {
            initializeRetentionDates();
        }
    }, [invoices, isInvoice]);

    const handlePay = async (invoice_id) => {
        try {
            setLoading(true);
            const endpoint = isInvoice ? 'pay_invoice' : 'pay_retention';
            const amounts = isInvoice ? invoicesAmounts : retentionsAmounts;
            const amountToPay = amounts[invoice_id];

            if (!amountToPay || amountToPay <= 0) {
                alert("Por favor, introduzca un valor válido.");
                return;
            }
            const result = await api().patch(`/invoices/${endpoint}`, { invoice_id: invoice_id, amount: amountToPay });
            if (result.status < 300) {
                alert('Pago realizado correctamente');
                fetch();
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleRetentionDateChange = (invoice_id, newValue) => {
        let formattedDate = dayjs(newValue).hour(12).minute(0).second(0);
        formattedDate = formattedDate.utc().format('YYYY-MM-DD');
        setNewDate(prev => ({
            ...prev,
            [invoice_id]: formattedDate
        }));
    };

    const changeRetentionDate = async (invoice_id) => {
        try {
            setLoading(true);
            const retentionDate = newDate[invoice_id];
            if (!retentionDate) {
                alert("Por favor, seleccione una fecha válida.");
                return;
            }
            const result = await api().patch(`/invoices/edit_retention_limit_date`, { invoice_id: invoice_id, retention_limit_date: retentionDate });
            if (result.status < 300) {
                alert('Fecha cambiada correctamente');
                fetch();
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };


    const handleAmountChange = (id, value) => {
        const invoicesList = invoices.filter(item => item.id === id)[0];
        let remainingAmount;
        let newErrors = {};

        if (isInvoice) {
            remainingAmount = invoicesList.total_price - (invoicesList.paid_amount ?? 0) - (invoicesList.retention ?? 0);

            if (value <= 0) {
                newErrors[id] = "El monto debe ser mayor a 0.";
            } else if (value > remainingAmount) {
                newErrors[id] = `El monto no puede exceder el restante (${remainingAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}€).`;
            } else {
                delete newErrors[id];
            }

            setInvoicesAmounts(prev => ({
                ...prev,
                [id]: value
            }));
            setInvoicesErrors(newErrors);
        } else {
            remainingAmount = invoicesList.retention - (invoicesList.paid_retention ?? 0);

            if (value <= 0) {
                newErrors[id] = "El monto debe ser mayor a 0.";
            } else if (value > remainingAmount) {
                newErrors[id] = `El monto no puede exceder el restante (${remainingAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}€).`;
            } else {
                delete newErrors[id];
            }

            setRetentionsAmounts(prev => ({
                ...prev,
                [id]: value
            }));
            setRetentionsErrors(newErrors);
        }
    };
    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            alert(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    // Determine table headers based on isInvoice
    const headers = isInvoice ? [
        { label: 'Código', width: '5%' },
        { label: 'Fecha Creación', width: '5%' },
        { label: 'Fecha vencimiento', width: '5%' },
        ...(showBuilding ? [{ label: 'Obra', width: '8%' }] : []),
        ...(showClientOrProvider ? [{ label: isClient ? 'Cliente' : 'Proveedor', width: '5%' }] : []),
        { label: 'Estado', width: '5%' },
        { label: 'Importe', width: '8%' },
        { label: 'Retención', width: '8%' },
        { label: 'Pagado', width: '8%' },
        { label: 'Restante', width: '6%' },
        { label: 'Cantidad a pagar', width: '8%' },
        { label: 'Acciones', width: '32%' }
    ] : [
        { label: 'Código Factura', width: '5%' },
        { label: 'Fecha Factura', width: '5%' },
        { label: 'Fecha vencimiento', width: '8%' },
        ...(showBuilding ? [{ label: 'Obra', width: '5%' }] : []),
        ...(showClientOrProvider ? [{ label: isClient ? 'Cliente' : 'Proveedor', width: '5%' }] : []),
        { label: 'Estado', width: '5%' },
        { label: 'Importe', width: '8%' },
        { label: 'Pagado', width: '8%' },
        { label: 'Restante', width: '6%' },
        { label: 'Cantidad a pagar', width: '8%' },
        { label: 'Acciones', width: '27%' }
    ];

    return (
        <Accordion title={title}>
            <Box sx={{
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                gap: '16px', padding: '8px 16px', marginBottom: '16px',
                backgroundColor: '#f5f5f5', borderRadius: '8px'
            }}>
                <Typography variant="h8" component="span">
                    <strong>Total Importe:</strong> {formatPrice(totalAmount)}
                </Typography>
                <Typography variant="h8" component="span">
                    <strong>Total Pagado:</strong> {formatPrice(paidAmount)}
                </Typography>
                <Typography variant="h8" component="span">
                    <strong>Total Restante:</strong> {formatPrice(remainingAmountSum)}
                </Typography>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell key={index} style={{ width: header.width }}>
                                    {header.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.filter(item => !item.disabled).map((item, index) => {
                            const remainingAmount = isInvoice
                                ? item.total_price - (item.paid_amount ?? 0) - (item.retention ?? 0)
                                : item.retention - (item.paid_retention ?? 0);
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Link to={`/invoices/update/${item.id}`}>
                                            {item.code}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{formatDate(item.invoice_date)}</TableCell>
                                    {isInvoice ? (
                                        <TableCell>{formatDate(item.invoice_limit_date)}</TableCell>
                                    ) : (
                                        <TableCell>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                                adapterLocale="es"
                                                localeText={{
                                                    cancelButtonLabel: "cancelar",
                                                    okButtonLabel: "Ok",
                                                    datePickerToolbarTitle: 'Seleccionar',
                                                }}>
                                                <DesktopDatePicker
                                        inputFormat="DD/MM/YYYY"
                                                    label="Fecha fin retención"
                                                    closeOnSelect={true}
                                                    value={newDate[item.id] ? dayjs(newDate[item.id]) : null}
                                                    onChange={(newValue) => handleRetentionDateChange(item.id, newValue)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </TableCell>
                                    )}
                                    {showBuilding && (
                                        <TableCell>
                                            {item.building ? (
                                                <Link to={`/buildings/update/${item.building.id}`}>
                                                    {item.building.code || '-'}
                                                </Link>
                                            ) : (
                                                '-'
                                            )}
                                        </TableCell>
                                    )}
                                    {showClientOrProvider && (
                                        <TableCell>
                                            <Link to={`/${isClient ? 'clients' : 'providers'}/update/${item[isClient ? 'client' : 'provider'].id}`}>
                                                {item[isClient ? 'client' : 'provider'].code}
                                            </Link>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {isInvoice
                                            ? item.invoice_status === 'paid' ? 'PAGADA' :
                                                item.invoice_status === 'not_paid' ? 'NO PAGADA' :
                                                    item.invoice_status
                                            : item.retention_status === 'paid' ? 'PAGADA' :
                                                item.retention_status === 'not_paid' ? 'NO PAGADA' :
                                                    item.retention_status}
                                    </TableCell>
                                    <TableCell>
                                        {isInvoice
                                            ? formatPrice(item.total_price)
                                            : formatPrice(item.retention)
                                        }
                                    </TableCell>

                                    {isInvoice && (
                                        <TableCell>
                                            {formatPrice(item.retention)}
                                        </TableCell>
                                    )}

                                    <TableCell>
                                        {isInvoice
                                            ? formatPrice(item.paid_amount)
                                            : formatPrice(item.paid_retention)
                                        }
                                    </TableCell>

                                    <TableCell>
                                        {formatPrice(remainingAmount)}
                                    </TableCell>
                                    <TableCell>
                                        <DecimalTextField
                                            type="number"
                                            value={isInvoice ? invoicesAmounts[item.id] || '' : retentionsAmounts[item.id] || ''}
                                            onChange={(e) => handleAmountChange(item.id, e.target.value)}
                                            fullWidth
                                            error={!!(isInvoice ? invoicesErrors[item.id] : retentionsErrors[item.id])}
                                            helperText={isInvoice ? invoicesErrors[item.id] : retentionsErrors[item.id]}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handlePay(item.id)}
                                            sx={{ marginRight: '8px' }}
                                            disabled={remainingAmount <= 0 || !!(isInvoice ? invoicesErrors[item.id] : retentionsErrors[item.id]) || !(isInvoice ? invoicesAmounts[item.id] : retentionsAmounts[item.id]) || loading}
                                        >
                                            Pagar
                                        </Button>
                                        {item.documents && item.documents.length > 0 && (() => {
                                            const activeDocument = item.documents.find(doc => !doc.disabled);
                                            return activeDocument ? (
                                                <Button
                                                    onClick={() =>
                                                        handleDownloadDocument(activeDocument.id, activeDocument.name)
                                                    }
                                                    sx={{ marginRight: 2 }}
                                                >
                                                    {activeDocument.name}
                                                </Button>
                                            ) : null;
                                        })()}
                                        {uploadDocument ? (
                                            <UploadDocument
                                                to={'invoice_id'}
                                                id={item.id}
                                                fetch={fetch}
                                            >
                                            </UploadDocument>
                                        ) : (isInvoice && isClient) ? (
                                            <>
                                                <CreatePdf
                                                    endpoint={`/invoices/${item.id}/generate-pdf/proform`}
                                                    text="Ver proforma"
                                                />
                                                <CreatePdf
                                                    endpoint={`/invoices/${item.id}/generate-pdf/invoice`}
                                                />
                                            </>
                                        ) : null}
                                        {isInvoice ? (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleOpenDeleteDialog(item.id, '/invoices/disable')}
                                            >
                                                Borrar
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: 'green', color: 'white', marginRight: '10px' }}
                                                disabled={loading}
                                                onClick={() => changeRetentionDate(item.id)}
                                            >
                                                Cambiar fecha
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Accordion>
    );
};

export default InvoiceRetentionTable;
