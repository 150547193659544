import React from "react";
import logimsaImage from '../images/logimsa.png';
import { Link } from 'react-router-dom';
import Cards from "../components/Cards";
import { useAuth } from "./login/OAuth"
import {
  Button,
  Container,
  Box,
  Typography
} from "@mui/material";

export default function Home() {
  const { authenticated, disabled } = useAuth();

  return (

    <div>
      {authenticated ? (
        <Cards />
      )
        : (!disabled ? (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logimsaImage} alt="Logo" />
          <br/>
          <br/>
          <Typography component="h1" variant="h5">
            Bienvenido a logimsa
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <Link to={`/sign-in`} variant="body2">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: '#E66136', color: 'white', '&:hover': {backgroundColor: '#913d21'}}}
            >
              INICIAR SESIÓN
            </Button>
            </Link>
          </Box>
        </Box>
      </Container>
        )
          :
          <h1>Checking your request</h1>
        )

      }

    </div>
  );
}
