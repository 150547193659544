import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Autocomplete,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    FormHelperText,
    Paper,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    Box,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import Accordion from '../../components/Accordion';
import CreatePdf from '../../components/CreatePdf';

dayjs.extend(utc);

function EditBuilding() {
    const { id } = useParams();
    const { api } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [editError, setEditError] = useState(null);
    const [itemsError, setItemsError] = useState(null);

    const [client, setClient] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [items, setItems] = useState([]);

    const [budget, setBudget] = useState(0);

    const [status, setStatus] = useState('');

    const [firstStatusOffer, setFirstStatusOffer] = useState(false);

    const [offerForm, setOfferForm] = useState({
        name: null,
        start_date: null,
        end_date: null,
        description: null,
        offer_status: null,
        client_id: null,
        building_created: null,
    });

    const [openItemModal, setOpenItemModal] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [typeDelete, setTypeDelete] = useState(false);

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [infoContent, setInfoContent] = useState({ name: '', description: '' });

    const [itemForm, setItemForm] = useState({
        name: null,
        description: null,
        unit_price: null,
        quantity: null,
        unit: null,
    });
    const [itemFormErrors, setItemFormErrors] = useState({
        name: '',
        description: '',
        unit_price: '',
        quantity: '',
        unit: ''
    });
    const fetchOffer = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get(`/offers/${id}`);
            const offer = result.data
            setOfferForm(offer);
            setBudget(offer.budget);
            setOfferForm(prevState => ({ ...prevState, offer_id: id }));
            setSelectedClient(offer.client);
            setClient(offer.client);
            setStatus(offer.offer_status);
            setItems(offer.items);
            setFirstStatusOffer(offer.offer_status)
            setLoading(false);
        } catch (error) {
            setEditError('Error obteniendo oferta', error);
            setLoading(false);
        }
    }, [api, id]);

    const fetchClients = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get('/clients/');
            setClients(result.data[0]);
            setLoading(false);
        } catch (error) {
            setEditError(`Error al obtener clientes: ${error.message}`);
        }
    }, [api]);

    useEffect(() => {
        fetchOffer();
        fetchClients();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setOfferForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleStatusChange = (event) => {
        const { value } = event.target;
        setStatus(value);
        setOfferForm((prevState) => ({
            ...prevState,
            offer_status: value,
        }));
    };
    const handleDateChange = (name, newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        setOfferForm((prevState) => ({
            ...prevState,
            [name]: date,
        }));
    };

    const handleClientChange = (event, newValue) => {
        setSelectedClient(newValue);
        setOfferForm((prevState) => ({
            ...prevState,
            client_id: newValue ? newValue.id : '',
        }));
    };

    const validateForm = () => {
        if (!offerForm.name) {
            return 'Por favor, añada un nombre';
        }
        if (offerForm.name.length > 36) {
            return 'El nombre no debe exceder los 36 caracteres';
        }
        if (!offerForm.client_id) {
            return 'Por favor, seleccione un cliente';
        }
        if (!offerForm.offer_status) {
            return 'Por favor, seleccione el estado de la obra';
        }

        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setEditError(null);
        const validationError = validateForm();
        if (validationError) {
            setEditError(validationError);
            return;
        }
        try {
            setLoading(true);
            const result = await api().patch(`/offers/`, offerForm);
            if (result.status < 300) {
                alert('Oferta actualizada correctamente');
                const offer = result.data
                setOfferForm(offer);
                setOfferForm(prevState => ({ ...prevState, offer_id: id }));
                setSelectedClient(offer.client);
                setClient(offer.client);
                setStatus(offer.offer_status);
                setFirstStatusOffer(offer.offer_status);
                setEditError(null);
            } else {
                setEditError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setEditError(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateBuilding = async (e) => {
        setEditError(null);
        try {
            setLoading(true);
            const result = await api().post(`/offers/create_building`, { offer_id: id });
            if (result.status < 300) {
                console.log(result.data);
                alert('Obra creada correctamente');
                navigate(`/buildings/update/${result.data.id}`);
                setEditError(null);
            } else {
                setEditError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setEditError(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenItemModal = () => {
        setItemForm((prevState) => ({
            ...prevState,
            offer_id: id,
        }));
        setOpenItemModal(true);
    };

    const handleCloseItemModal = () => {
        setOpenItemModal(false);
        setItemForm({ name: null, description: null, quantity: null, unit: null, unit_price: null });
        setItemFormErrors({ name: '', description: '', quantity: '', unit: '', unit_price: '' });
    };

    const handleItemChange = (event) => {
        const { name, value } = event.target;
        setItemForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setItemFormErrors((prevState) => ({
            ...prevState,
            [name]: ''
        }));
    };
    const handleItemSubmit = async () => {
        try {
            const errors = validateItemForm();
            if (Object.keys(errors).length > 0) {
                setItemFormErrors(errors);
                return;
            }
            setItemsError(null);
            setLoading(true);
            const result = await api().post(`/offers/add_item`, itemForm);
            if (result.status < 300) {
                alert('Partida añadida correctamente');
                handleCloseItemModal();
                setItemForm((prevState) => ({
                    ...prevState,
                    name: null,
                    description: null,
                    quantity: null,
                    unit: null,
                    unit_price: null
                }));
                const offer = result.data
                setBudget(offer.budget);
                setItems(offer.items);
            } else {
                setItemsError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setItemsError(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteItem = async () => {
        try {
            let data = {}
            if (typeDelete === '/offers/disable') data.offer_id = deleteId
            if (typeDelete === '/offers/disable_item') data.item_id = deleteId

            const result = await api().patch(`${typeDelete}`, data);

            if (result.status < 300) {
                alert('Eliminado correctamente');
                if (typeDelete === '/offers/disable') {
                    navigate(`/offers/list`);
                } else {
                    const offer = result.data;
                    setBudget(offer.budget);
                    setItems(offer.items);
                }
                setOpenDeleteDialog(false);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`)

            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`)
        };
    }
    const handleOpenDeleteDialog = (itemId, type) => {
        setDeleteId(itemId);
        setTypeDelete(type);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleInfoOpen = (content) => {
        setInfoContent(content);
        setOpenInfoModal(true);
    };
    const handleInfoClose = () => {
        setOpenInfoModal(false);
        setInfoContent({ name: '', description: '' });
    };
    const validateItemForm = () => {
        const errors = {};
        if (!itemForm.name) {
            errors.name = 'El nombre es requerido';
        }
        if (!itemForm.unit_price) {
            errors.unit_price = 'El precio unitario es requerido';
        } else {
            const unit_price = parseFloat(itemForm.unit_price);
            if (unit_price > 99000000) {
                errors.unit_price = "El precio unitario no puede ser mayor a 99 millones.";
            } else if (!Number.isInteger(unit_price * 100)) {
                errors.unit_price = "El precio unitario solo puede tener hasta 2 decimales.";
            }
            else if (unit_price <= 0) {
                errors.unit_price = 'El precio unitario debe ser mayor que 0';
            }
        }
        if (!itemForm.quantity) {
            errors.quantity = 'La cantidad es requerida';
        } else {
            const quantity = parseFloat(itemForm.quantity);
            if (quantity > 99000000) {
                errors.quantity = "La cantidad no puede ser mayor a 99 millones.";
            } else if (!Number.isInteger(quantity * 100)) {
                errors.quantity = "La cantidad solo puede tener hasta 2 decimales.";
            }
            else if (itemForm.quantity <= 0) {
                errors.quantity = 'La cantidad debe ser mayor que 0';
            }
        }
        if (!itemForm.unit) {
            errors.unit = 'La unidad es requerida';
        }
        return errors;
    };

    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                <Grid item>
                    <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                        <Box mb={2}>
                            <Typography variant="h5" component="h1" gutterBottom>
                                {offerForm.code} - {offerForm.name || ''}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Grid item xs={12} md={10} lg={8}>
                <Accordion title={`${offerForm.code} ${offerForm.name}`}>
                    <form onSubmit={handleSubmit} style={{ padding: '10px' }}>
                        <Grid spacing={2} style={{ padding: 8 }} >
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Ver Oferta:</Typography>
                            <CreatePdf endpoint={`/offers/${id}/generate-pdf`}></CreatePdf>
                        </Grid>
                        <Grid container spacing={2} style={{ padding: 8 }}>
                            {/* Primera fila: Nombre, Presupuesto, Cliente y Estado */}
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Nombre"
                                    name="name"
                                    value={offerForm.name || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    inputProps={{ maxLength: 36 }}
                                    InputLabelProps={{ shrink: !!offerForm.name }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Autocomplete
                                    options={clients}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={selectedClient || null}
                                    onChange={handleClientChange}
                                    renderInput={(params) => <TextField {...params} label="Cliente" margin="normal" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="Presupuesto"
                                    name="budget"
                                    value={budget.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}
                                    margin="normal"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth disabled={firstStatusOffer !== 'pending'} margin="normal">
                                    <InputLabel id="status-label">Estado</InputLabel>
                                    <Select
                                        labelId="status-label"
                                        id="status"
                                        name="status"
                                        value={status}
                                        onChange={handleStatusChange}
                                        label="Estado"
                                    >
                                        <MenuItem value="pending">PENDIENTE</MenuItem>
                                        <MenuItem value="accepted">ACEPTADA</MenuItem>
                                        <MenuItem value="rejected">RECHAZADA</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Segunda fila: Fecha inicio y Fecha final */}
                            <Grid item xs={12} sm={2}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="es"
                                    localeText={{
                                        cancelButtonLabel: "cancelar",
                                        okButtonLabel: "Ok",
                                        datePickerToolbarTitle: 'Seleccionar',
                                    }}>
                                    <DesktopDatePicker
                                        inputFormat="DD/MM/YYYY"
                                        label="Fecha inicio"
                                        closeOnSelect={true}
                                        value={offerForm.start_date ? dayjs(offerForm.start_date) : null}
                                        onChange={(newValue) => handleDateChange('start_date', newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="es"
                                    localeText={{
                                        cancelButtonLabel: "cancelar",
                                        okButtonLabel: "Ok",
                                        datePickerToolbarTitle: 'Seleccionar',
                                    }}>
                                    <DesktopDatePicker
                                        inputFormat="DD/MM/YYYY"
                                        label="Fecha final"
                                        closeOnSelect={true}
                                        value={offerForm.end_date ? dayjs(offerForm.end_date) : null}
                                        onChange={(newValue) => handleDateChange('end_date', newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            {/* Tercera fila: Descripción */}
                            <Grid item xs={12}>
                                <TextField
                                    label="Descripción"
                                    name="description"
                                    value={offerForm.description || ''}
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    margin="normal"
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>

                            {/* Cuarta fila: Botones */}
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={firstStatusOffer !== 'pending' ? 4 : 6}>
                                    <Button variant="contained" color="secondary" onClick={() => handleOpenDeleteDialog(id, '/offers/disable')} fullWidth style={{ backgroundColor: 'red' }}>
                                        Eliminar
                                    </Button>
                                </Grid>
                                <Grid item xs={firstStatusOffer !== 'pending' ? 4 : 6}>
                                    <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                        Actualizar
                                    </Button>
                                </Grid>
                                {firstStatusOffer !== 'pending' && (
                                    <Grid item xs={4}>
                                        <Button variant="contained" color="success" disabled={offerForm.building_created} onClick={handleCreateBuilding} fullWidth>
                                            Crear Obra
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>

                            {/* Error */}
                            {editError && (
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {editError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>

                    </form>
                </Accordion>
                <Accordion title='Partidas de la obra'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Button variant="contained" color="primary" onClick={handleOpenItemModal} sx={{ mb: 2 }}>
                            Añadir Partida
                        </Button>
                        <p style={{ marginBottom: 30 }}><strong>Presupuesto: </strong>{budget.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}</p>
                    </div>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Descripción</TableCell>
                                    <TableCell>Precio unitario</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Importe total</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.filter(item => !item.disabled).map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                            {item.description
                                                ? (item.description.length > 50
                                                    ? item.description.substring(0, 50) + '...'
                                                    : item.description)
                                                : ''}
                                        </TableCell>
                                        <TableCell>{item.unit_price ? item.unit_price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : null}</TableCell>
                                        <TableCell>{item.quantity ? item.quantity.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null} {item.unit === 'l' ? 'LITROS' :
                                            item.unit === 'kg' ? 'KGS' :
                                                item.unit === 'uds' ? 'UNIDADES' :
                                                    item.unit === 'hour' ? 'HORAS' :
                                                        item.unit === 'm3' ? 'M3' :
                                                            item.unit}
                                        </TableCell>
                                        <TableCell>
                                            {item.unit_price && item.quantity
                                                ? (item.unit_price * item.quantity).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'
                                                : '0,00€'}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleInfoOpen({ name: item.name, description: item.description })}
                                            >
                                                Ver descripción
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleOpenDeleteDialog(item.id, '/offers/disable_item')}
                                                sx={{ ml: 2 }}
                                            >
                                                Borrar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {itemsError && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {itemsError}
                        </Typography>
                    )}
                </Accordion>
                <Accordion title='Detalles del cliente'>
                    <Grid container spacing={3} style={{ marginBottom: 20 }}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Nombre:</Typography>
                            <Typography variant="body1">{client.name} {client.surname}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Código cliente:</Typography>
                            <Typography variant="body1">
                                <Link to={`/clients/update/${client.id}`}>
                                    {client.code || '-'}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Correo:</Typography>
                            <Typography variant="body1">{client.email || '-'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Teléfono:</Typography>
                            <Typography variant="body1">{client.telephone_number || '-'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">CIF:</Typography>
                            <Typography variant="body1">{client.cif || '-'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Dirección:</Typography>
                            <Typography variant="body1">{client.country} {client.town} {client.province} {client.cp} {client.address}</Typography>
                        </Grid>
                    </Grid>
                </Accordion>

                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                    <DialogTitle>¿Estás seguro?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="primary">
                            Volver
                        </Button>
                        <Button onClick={handleDeleteItem} color="error">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openItemModal} onClose={handleCloseItemModal} maxWidth="sm" fullWidth>
                    <DialogTitle>Añadir Partida</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Por favor, complete el formulario para añadir una nueva partida.
                        </DialogContentText>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={itemForm.name || ''}
                                onChange={handleItemChange}
                                error={!!itemFormErrors.name}
                                helperText={itemFormErrors.name}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Descripción"
                                name="description"
                                value={itemForm.description || ''}
                                onChange={handleItemChange}
                                error={!!itemFormErrors.description}
                                helperText={itemFormErrors.description}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Precio Unitario"
                                name="unit_price"
                                type="number"
                                value={itemForm.unit_price || ''}
                                onChange={handleItemChange}
                                error={!!itemFormErrors.unit_price}
                                helperText={itemFormErrors.unit_price}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Cantidad"
                                name="quantity"
                                type="number"
                                value={itemForm.quantity || ''}
                                onChange={handleItemChange}
                                error={!!itemFormErrors.quantity}
                                helperText={itemFormErrors.quantity}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <FormControl fullWidth error={!!itemFormErrors.unit}>
                                <InputLabel id={`unit-label`}>Unidad</InputLabel>
                                <Select
                                    labelId={`unit-label`}
                                    name="unit"
                                    fullWidth
                                    value={itemForm.unit || ''}
                                    onChange={handleItemChange}
                                    label="Unidad"
                                >
                                    <MenuItem value="uds">UDS</MenuItem>
                                    <MenuItem value="kg">KG</MenuItem>
                                    <MenuItem value="l">L</MenuItem>
                                    <MenuItem value="hour">HORA</MenuItem>
                                    <MenuItem value="m3">M3</MenuItem>
                                </Select>
                                {!!itemFormErrors.unit && (
                                    <FormHelperText>{itemFormErrors.unit}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseItemModal} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={handleItemSubmit} disabled={!itemForm.unit || !itemForm.unit_price || !itemForm.name || !itemForm.quantity} color="primary">
                            Añadir Partida
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openInfoModal} onClose={handleInfoClose}>
                    <DialogTitle>Información</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Nombre: {infoContent.name}</DialogContentText>
                        <DialogContentText>Descripción: {infoContent.description}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleInfoClose} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </div >
    );
};

export default EditBuilding;
