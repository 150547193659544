import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    CircularProgress,
    Typography,
    TextField,
    Button,
    Card,
    CardContent,
    CardHeader,
    TableCell,
    TableRow,
    Alert,
    TableHead,
    TableBody,
    Grid,
    TableContainer,
    Table,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import CreatePdf from '../../components/CreatePdf';
import { formatDate } from '../../components/Utils';

dayjs.extend(utc);

function EditCertification() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);

    const [certificationData, setCertificationData] = useState(null);
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false); 
    const [invoiceLimitDate, setInvoiceLimitDate] = useState(null);
    const [retentionDate, setRetentionDate] = useState(null);
    const [vat, setVat] = useState(0);
    const [retention, setRetention] = useState(0);

    const [retentionError, setRetentionError] = useState('');
    const [vatError, setVatError] = useState('');

    const fetchCertification = useCallback(async () => {
        try {
            const result = await api().get(`/certifications/${id}`);
            setCertificationData(result.data);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo certificación");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchCertification();
    }, []);

    const invoiceData = certificationData?.invoice;
    const invoiceBase = invoiceData ? invoiceData.base_price || 0 : 0;
    const retentionAmount = invoiceData ? invoiceData.retention || 0 : 0;
    const total = (invoiceData?.total_price || 0) - retentionAmount;
    const vatAmount = (invoiceData?.total_price || 0) - invoiceBase;

    const handleOpenInvoiceModal = () => setOpenInvoiceModal(true);
    const handleCloseInvoiceModal = () => setOpenInvoiceModal(false);

    const handleCreateInvoice = async () => {
        try {
            setLoading(true);
            const result = await api().post(`/invoices/new`, {
                certification: id,
                retention: retention,
                vat: vat,
                invoice_limit_date: invoiceLimitDate,
                retention_limit_date: retentionDate
            });
            if (result.status < 300) {
                alert('Factura creada correctamente');
                handleCloseInvoiceModal();
                fetchCertification();
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (name, newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        if (name === 'retention_limit_date') {
            setRetentionDate(date);
        } else if (name === 'invoice_limit_date') {
            setInvoiceLimitDate(date);
        }
    };

    const handleChangeRetention = (event) => {
        const { value } = event.target;

        setRetention(value);

        const retentionValue = parseFloat(value);

        if (isNaN(retentionValue) || retentionValue < 0 || retentionValue > 100 || !Number.isInteger(retentionValue)) {
            setRetentionError('La retención debe ser un número entero entre 0 y 100');
        } else {
            setRetentionError('');
        }
    };

    const handleChangeVat = (event) => {
        const { value } = event.target;

        setVat(value);

        const vatValue = parseFloat(value);

        if (isNaN(vatValue) || vatValue < 0 || vatValue > 100 || !Number.isInteger(vatValue)) {
            setVatError('El IVA debe ser un número entero entre 0 y 100');
        } else {
            setVatError('');
        }
    };

    const calculateTotalFromProgress = () => {
        if (!certificationData || !certificationData.item_progress) {
            return 0;
        }
        return certificationData.item_progress.reduce((acc, progress) => {
            const item = progress.item;
            const totalPrice = (item.total_price * progress.percentage) / 100;
            return acc + totalPrice;
        }, 0);
    };

    const totalFromProgress = calculateTotalFromProgress();

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {/* Mostramos un mensaje de error si existe */}
                    {error && (
                        <Alert severity="error" sx={{ marginBottom: '20px' }}>
                            {error}
                        </Alert>
                    )}
                    <Card sx={{ margin: 'auto' }}>
                        <CardHeader
                            title={
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                        {`Certificación: ${certificationData.code} | Fecha: ${formatDate(certificationData.certification_date) || '-'}`}
                                    </span>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <CreatePdf
                                            endpoint={`/certifications/${certificationData.id}/generate-pdf`}
                                        />
                                    </div>
                                </div>
                            }
                            sx={{ backgroundColor: '#f5f5f5', textAlign: 'center', fontWeight: 'bold' }}
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                {/* Tarjeta de Información del Cliente */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                            Información del Cliente
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                            <strong>Código: </strong>
                                            <Link to={`/clients/update/${certificationData.client.id}`}>
                                                {certificationData.client.code || '-'} <br />
                                            </Link>
                                            <strong>Nombre: </strong> {certificationData.client.name || '-'} <br />
                                            <strong>Email: </strong> {certificationData.client.email || '-'} <br />
                                            <strong>Teléfono: </strong> {certificationData.client.telephone_number || '-'} <br />
                                            <strong>CIF: </strong> {certificationData.client.cif || '-'} <br />
                                        </Typography>
                                    </Paper>
                                </Grid>

                                {/* Tarjeta de Factura */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                            Información de la Factura
                                        </Typography>
                                        {certificationData.invoice ? (
                                            <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                                <strong>Código: </strong>
                                                <Link to={`/invoices/update/${certificationData.invoice.id}`}>
                                                    {certificationData.invoice.code || '-'} <br />
                                                </Link>
                                                <strong>Fecha de la Factura: </strong> {formatDate(certificationData.invoice.invoice_date) || '-'} <br />
                                                <strong>Fecha vencimiento: </strong> {formatDate(certificationData.invoice.invoice_limit_date) || '-'} <br />
                                                <strong>Base imponible: </strong> {invoiceBase.toFixed(2) }€ <br />
                                                <strong>IVA: </strong> {certificationData.invoice.vat}% ({vatAmount.toFixed(2)}€) <br />
                                                <strong>Retención: </strong> {retentionAmount.toFixed(2)}€ <br />
                                                <strong>Total: </strong> {total.toFixed(2)}€ <br />
                                            </Typography>
                                        ) : (
                                            <Button variant="contained" color="primary" onClick={handleOpenInvoiceModal}>
                                                Facturar
                                            </Button>
                                        )}
                                    </Paper>
                                </Grid>
                            </Grid>

                            {/* Tabla de la Certificación */}
                            <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginTop: '20px' }}>
                                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                    Obra:
                                    <Link to={`/buildings/update/${certificationData.building.id}`}>
                                        {certificationData.building.code || '-'}
                                    </Link> - {certificationData.building.name || '-'}
                                </Typography>

                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Partida</strong></TableCell>
                                                <TableCell><strong>Porcentaje</strong></TableCell>
                                                <TableCell><strong>Precio</strong></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {certificationData.item_progress.map((progress) => {
                                                const item = progress.item;
                                                const totalPrice = (item.total_price * progress.percentage) / 100;

                                                return (
                                                    <TableRow key={progress.id}>
                                                        <TableCell>{item.name || '-'}</TableCell>
                                                        <TableCell>{progress.percentage || '-'}%</TableCell>
                                                        <TableCell>{item.total_price.toFixed(2)} €</TableCell>
                                                        <TableCell>{totalPrice.toFixed(2)} €</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {/* Información Adicional */}
                                            <TableRow>
                                                <TableCell rowSpan={3} colSpan={2}></TableCell>
                                                <TableCell><strong>Base imponible</strong></TableCell>
                                                <TableCell>{totalFromProgress.toFixed(2)} €</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><strong>Total</strong></TableCell>
                                                <TableCell><strong>{totalFromProgress.toFixed(2)} €</strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>
                    </Card>

                    {/* Modal de Crear Factura */}
                    <Dialog open={openInvoiceModal} onClose={handleCloseInvoiceModal}>
                        <DialogTitle>Crear Factura</DialogTitle>
                        <DialogContent>
                            <Grid >
                                <Grid >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <DesktopDatePicker
                                        inputFormat="DD/MM/YYYY"
                                            label="Fecha vencimiento factura"
                                            closeOnSelect={true}
                                            onChange={(newValue) => handleDateChange('invoice_limit_date', newValue)}
                                            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid >
                                    <TextField
                                        name="vat"
                                        label="IVA (%)"
                                        fullWidth
                                        value={vat}
                                        onChange={handleChangeVat}
                                        margin="normal"
                                        error={!!vatError}
                                        helperText={vatError}
                                        type="number"
                                    />
                                </Grid>
                                <Grid >
                                    <TextField
                                        name="retention"
                                        label="Retención (%)"
                                        fullWidth
                                        value={retention}
                                        onChange={handleChangeRetention}
                                        margin="normal"
                                        type="number"
                                        error={!!retentionError}
                                        helperText={retentionError}
                                    />
                                </Grid>
                                <Grid >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <DesktopDatePicker
                                        inputFormat="DD/MM/YYYY"
                                            label="Fecha vencimiento retención"
                                            closeOnSelect={true}
                                            onChange={(newValue) => handleDateChange('retention_limit_date', newValue)}
                                            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseInvoiceModal} color="primary">
                                Cancelar
                            </Button>
                            <Button color="primary" disabled={!retention || !invoiceLimitDate || !retentionDate} onClick={handleCreateInvoice}>
                                Crear Factura
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </div>
    );
}

export default EditCertification;
